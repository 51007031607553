import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { Avatar, ListItemAvatar, ListItemText, MenuItem, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { v4 } from 'uuid';
// import { setAllAgencies } from '../../../redux/slices/allAgencies';

import { url } from '../../../config';
import useAuth from '../../../hooks/useAuth';
import { userActions } from '../../../redux/slices/allUsers';

import { setCurrentUserPermissions } from '../../../redux/slices/currentUserPermissions';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
// components
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import Scrollbar from '../../../components/Scrollbar';
// import { setAllAccounts } from '../../../redux/slices/allAccounts';
import { setAllCalendar } from '../../../redux/slices/allCalendar';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  // const [open, setOpen] = useState(null);
  const { user } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  useEffect(() => {
    const getCalendarView = async () => {
      try {
        let response = await axios.get(url.concat(`/leads/calender`), {
          headers: { Authorization: `Bearer ${user.token}` },
        });

        response = response.data;
        dispatch(setAllCalendar(response));
      } catch (err) {
        console.warn(err);
      }
    };
    getCalendarView();
  }, []);

  return <div />;
}
