import { createSlice, createAsyncThunk, createStore } from '@reduxjs/toolkit';
import axios from 'axios';
// import { HOST_URL } from '../../config';
import { HOST_URL } from '../../config';

const name = 'store';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const storeActions = { ...slice.actions, ...extraActions };

export default slice.reducer;

function createInitialState() {
  return {
    isLoading: false,
    items: [],
    gifts: [],
    error: null,
    totalGifts: 0,
    totalGiftPages: 0,
  };
}

function createExtraActions() {
  return {
    fetchStore: fetchStore(),
    createStoreItem: createStoreItem(),
    updateStoreItem: updateStoreItem(),
    updateGiftItem: updateGiftItem(),
    createGiftItem: createGiftItem(),
    deleteStoreEntry: deleteStoreEntry(),
    // fetchUserSearch:fetchUserSearch()
  };

  function fetchStore() {
    return createAsyncThunk(`${name}/fetch-store`, async ({ rowsPerPage, page, title, status }) =>
      axios.get(
        `${HOST_URL}store-items?limit=${rowsPerPage}&page=${page + 1}&name=${title}&status=${
          status === 'all' ? '' : status
        }`
      )
    );
  }

  // For creating Heart Item
  function createStoreItem() {
    return createAsyncThunk(`${name}/create-store-item`, async (values) =>
      axios.post(`${HOST_URL}store-items`, values)
    );
  }

  // For Updating store item
  function updateStoreItem() {
    return createAsyncThunk(`${name}/update-store-item`, async (values) =>
      axios.put(`${HOST_URL}store-items/${values._id}`, values)
    );
  }

  // For creating store gifts
  function createGiftItem() {
    return createAsyncThunk(`${name}/create-gift-item`, async (values) => axios.post(`${HOST_URL}store-items`, values));
  }

  // For Updating store item
  function updateGiftItem() {
    return createAsyncThunk(`${name}/update-gift-item`, async (values) =>
      axios.put(`${HOST_URL}store-items/${values._id}`, values)
    );
  }

  // for deleting store items and gifts
  function deleteStoreEntry() {
    return createAsyncThunk(`${name}/delete-store-entry`, async (_id) => axios.delete(`${HOST_URL}store-items/${_id}`));
  }
}

function createExtraReducers() {
  return {
    ...fetchStoreReducer(),
    ...createStoreItemReducer(),
    ...updateStoreItemReducer(),
    ...createStoreGiftReducer(),
    ...updateStoreGiftReducer(),
    ...deleteStoreEntry(),
  };

  function fetchStoreReducer() {
    const { pending, fulfilled, rejected } = extraActions.fetchStore;
    return {
      [pending]: (state) => {
        state.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        if (Array.isArray(action.payload?.data?.storeItems)) {
          state.isLoading = false;
          // state.items = action?.payload?.data?.storeItems?.filter((ele) => ele.ItemType === 'Heart');
          state.gifts = action?.payload?.data?.storeItems;
          state.totalGifts = action.payload?.data?.totalItems;
          state.totalGiftPages = action.payload?.data?.totalPages;
        } else {
          state.isLoading = false;
          state.items = [];
          state.gifts = [];
        }
      },
      [rejected]: (state, action) => {
        state.isLoading = false;
        state.items = [];
        state.gifts = [];
        state.error = action?.payload?.message;
      },
    };
  }

  // Reducer for updating Heart Item
  function createStoreItemReducer() {
    const { pending, fulfilled, rejected } = extraActions.createStoreItem;
    return {
      [pending]: (state) => {
        state.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.isLoading = false;
        state.items = [...state.items, action?.payload?.data];
      },
      [rejected]: (state, action) => {
        state.isLoading = false;
        state.items = [];
        state.error = action?.payload?.message;
      },
    };
  }

  // Reducer for updating Heart Item
  function updateStoreItemReducer() {
    const { pending, fulfilled, rejected } = extraActions.updateStoreItem;
    return {
      [pending]: (state) => {
        state.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        const index = state.items.indexOf(state.items.find((item) => item._id === action.payload?.data?._id));
        state.isLoading = false;
        state.items = [...state.items.slice(0, index), action?.payload?.data, ...state?.items?.slice(index + 1)];
      },
      [rejected]: (state, action) => {
        state.isLoading = false;
        state.items = [];
        state.error = action?.payload?.message;
      },
    };
  }

  // Reducer for creating gift
  function createStoreGiftReducer() {
    const { pending, fulfilled, rejected } = extraActions.createGiftItem;
    return {
      [pending]: (state) => {
        state.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.isLoading = false;
        state.gifts = [...state.gifts, action?.payload?.data];
      },
      [rejected]: (state, action) => {
        state.isLoading = false;
        state.gifts = [];
        state.error = action?.payload?.message;
      },
    };
  }

  // Reducer for updating gift
  function updateStoreGiftReducer() {
    const { pending, fulfilled, rejected } = extraActions.updateGiftItem;
    return {
      [pending]: (state) => {
        state.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        const index = state.gifts.indexOf(state.gifts.find((gift) => gift._id === action.payload?.data?._id));
        state.isLoading = false;
        state.gifts = [...state.gifts.slice(0, index), action?.payload?.data, ...state?.gifts?.slice(index + 1)];
      },
      [rejected]: (state, action) => {
        state.isLoading = false;
        state.gifts = [];
        state.error = action?.payload?.message;
      },
    };
  }

  // Reducer for deleting gift and item
  function deleteStoreEntry() {
    const { pending, fulfilled, rejected } = extraActions.deleteStoreEntry;
    return {
      [pending]: (state) => {
        state.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.isLoading = false;
        // state.gifts = state.gifts;
      },
      [rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action?.payload?.message;
      },
    };
  }
}
