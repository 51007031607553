import { createSlice, createAsyncThunk, createStore } from '@reduxjs/toolkit';
import axios from 'axios';
import { HOST_URL } from '../../config';

const name = 'store';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();

const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const audioClipsActions = { ...slice.actions, ...extraActions };

export default slice.reducer;

function createInitialState() {
  return {
    isLoading: false,
    audioClips: [],
    totalAudio: 0,
    error: null,
  };
}

function createExtraActions() {
  return {
    fetchAudios: fetchAudios(),
    createAudio: createAudio(),
    updateAudio: updateAudio(),
    deleteAudio: deleteAudio(),
  };

  function fetchAudios() {
    return createAsyncThunk(`${name}/game-audio`, async ({ search, page, limit }) =>
      axios.get(`${HOST_URL}game-audio?page=${page}&limit=${limit}&search=${search}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    );
  }

  function createAudio() {
    return createAsyncThunk(`${name}/createAudio`, async (values) =>
      // console.log(values)
      axios.post(`${HOST_URL}game-audio`, values, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    );
  }

  function updateAudio() {
    return createAsyncThunk(`${name}/updateAudio`, async (values) =>
      axios.patch(`${HOST_URL}game-audio/${values.id}`, values, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    );
  }

  function deleteAudio() {
    return createAsyncThunk(`${name}/deleteAds`, async ({ id }) =>
      axios.delete(`${HOST_URL}game-audio/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      })
    );
  }
}

function createExtraReducers() {
  return {
    ...fetchAudiosReducer(),
    ...createAudioReducer(),
    ...updateAudioReducer(),
    ...deleteAudioReducer(),
  };

  function fetchAudiosReducer() {
    const { pending, fulfilled, rejected } = extraActions.fetchAudios;
    return {
      [pending]: (state) => {
        state.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.isLoading = false;
        state.audioClips = action?.payload?.data?.data;
        state.totalAudio = action?.payload?.data?.count;
      },
      [rejected]: (state, action) => {
        state.isLoading = false;
        state.audioClips = [];
        state.error = action?.payload?.message;
      },
    };
  }

  function createAudioReducer() {
    const { pending, fulfilled, rejected } = extraActions.createAudio;
    return {
      [pending]: (state) => {
        state.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.isLoading = false;
        state.audioClips = [...state.audioClips, action?.payload?.data];
      },
      [rejected]: (state, action) => {
        state.isLoading = false;
        state.audioClips = [];
        state.error = action?.payload?.message;
      },
    };
  }

  // Reducer for updating Heart Item
  function updateAudioReducer() {
    const { pending, fulfilled, rejected } = extraActions.updateAudio;
    return {
      [pending]: (state) => {
        state.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.isLoading = false;
        state.audioClips = state.audioClips.map((item) =>
          action.payload.data._id === item._id ? action.payload.data : item
        );
      },
      [rejected]: (state, action) => {
        state.isLoading = false;
        state.audioClips = [];
        state.error = action?.payload?.message;
      },
    };
  }

  // Reducer for deleting gift and item
  function deleteAudioReducer() {
    const { pending, fulfilled, rejected } = extraActions.deleteAudio;
    return {
      [pending]: (state) => {
        state.isLoading = true;
      },
      [fulfilled]: (state, action) => {
        state.isLoading = false;
        state.audioClips = [...state.audioClips];
      },
      [rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action?.payload?.message;
      },
    };
  }
}
